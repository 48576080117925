var app = app || {},
    mode = 'test',
    assetURL = {
        local: 'https://s3.amazonaws.com/assets.ylpwsdev.com/',
        dev: 'https://s3.amazonaws.com/assets.ylpwsdev.com/',
        test: 'https://s3.amazonaws.com/assets.ylpws.com/',
        live: 'https://s3.amazonaws.com/assets.myyl.com/'
    },
    promoURL = {
        local: 'https://promo.ylpwsdev.com/',
        dev: 'https://promo.ylpwsdev.com/',
        test: 'https://promo.ylpws.com/',
        live: 'https://promo.myyl.com/'
    },
    commonURL = {
        local: 'http://localhost:8001/json/',
        dev: '/admin/json/',
        test: '/admin/json/',
        live: '/admin/json/'
    },
    servicesURL = {
        local: 'https://services.ylpwsdev.com/v7/',
        dev: 'https://services.ylpwsdev.com/v7/',
        test: 'https://services.ylpws.com/v7/',
        live: 'https://services.myyl.com/v7/'
    },
    cartURL = {
        local: 'https://shopping.ylpwsdev.com/',
        dev: 'https://shopping.ylpwsdev.com/',
        test: 'https://shopping.ylpws.com/',
        live: 'https://shopping.myyl.com/'
    },
    apiURL = {
        local: 'https://api.ylpwsdev.com/',
        dev: 'https://api.ylpwsdev.com/',
        test: 'https://api.ylpws.com/',
        live: 'https://api.myyl.com/'
    },
    ylURL = {
        local: 'http://localhost:8080/',
        dev: 'https://clone.youngliving.com/',
        test: 'https://www.youngliving.com/',
        live: 'https://www.youngliving.com/'
    },
    skavaEnabledCountries = ['us'],
    cb = '?v={inject:now}',
    touchEnabled = false;   // for enabling/disabling document click events to close drop-downs, etc. that don't work well with touch devices

/**
 * Global shared module for functions/variables shared across the entire site
 */
app.global = (function() {
    'use strict';
    var self = {
            
        /**
         * Initialize this module
         */
        init: function() {
            switch (location.hostname) {
                case 'localhost':
                case '0.0.0.0':
                    mode = 'local';
                break;
                case 'www.ylpwsdev.com':
                    mode = 'dev';
                break;
                case 'www.ylpws.com':
                    mode = 'test';
                break;
                case 'www.myyl.com':
                case 'myyl.com':
                    mode = 'live';
                break;
            }

            self.initTracking();
        },

        /**
         * Format currency based on region
         * If countryCode (au, de, mx, us, etc...) is passed, this will simply return the currency formatting object for use in the location switcher
         */
        currency: function(num, countryCode, hideLabel) {
            // requires accounting.js
            var options = {
                symbol: '$',
                decimal: '.',
                thousand: ',',
                precision: 2,
                format: '%s%v',
                label: 'USD'
            };
            
            if (!countryCode) {
                countryCode = app.main.getCountryCode();
            }

            switch (countryCode) {
                case 'ch':
                case 'cz':
                case 'es':
                case 'it':
                case 'pl':
                case 'ro':
                case 'ru':
                case 'sk':
                    // €1.324,00 EUR
                    options.symbol = '€';
                    options.label = 'EUR';
                    options.decimal = ',';
                    options.thousand = '.';
                break;
                
                case 'ad':
                case 'al':
                case 'am':
                case 'ba':
                case 'be':
                case 'bg':
                case 'by':
                case 'ic':
                case 'cy':
                case 'dk':
                case 'ee':
                case 'eu':
                case 'fo':
                case 'gi':
                case 'gr':
                case 'hr':
                case 'hu':
                case 'ie':
                case 'is':
                case 'it':
                case 'kg':
                case 'kz':
                case 'li':
                case 'lu':
                case 'lv':
                case 'mc':
                case 'md':
                case 'me':
                case 'mk':
                case 'mt':
                case 'no':
                case 'pl':
                case 'pt':
                case 'rs':
                case 'si':
                case 'sm':
                case 'ua':
                case 'va':
                    // €1,324.00 EUR
                    options.symbol = '€';
                    options.label = 'EUR';
                break;


                case 'at':
                case 'de':
                case 'fi':
                case 'lt':
                    // 36,39 €
                    options.symbol = ' €';
                    options.label = '';
                    options.decimal = ',';
                    options.thousand = '.';
                    options.format = '%v%s';
                break;

                case 'au':
                    // $12.99 AUD
                    options.label = 'AUD';
                break;

                case 'ca':
                    // $1,234.56 CAD
                    options.label = 'CAD';

                    // special case for fr-ca
                    // $#.###,00 CAD
                    if (app.main.getCurrentCulture() == 'fr-ca') {
                        options.decimal = ',';
                        options.thousand = '.';
                    }
                break;

                case 'fr':
                    // 36,39 € EUR
                    options.symbol = ' €';
                    options.label = 'EUR';
                    options.decimal = ',';
                    options.thousand = '.';
                    options.format = '%v%s';
                break;

                case 'gb':
                    // £34.74 GBP
                    options.symbol = '£';
                    options.label = 'GBP';
                break;

                case 'hk':
                    // HK$#,###.## HKD
                    options.symbol = 'HK$';
                    options.label = 'HKD';
                break;

                case 'id':
                    // Rp#,###,### IDR
                    options.symbol = 'Rp';
                    options.label = 'IDR';
                    options.precision = 0;
                break;

                case 'jp':
                    // Y#,###.## JPY
                    options.symbol = '¥';
                    options.label = 'JPY';
                break;

                case 'mx':
                    // $#,###.00 MXN
                    options.label = 'MXN';
                break;

                case 'my':
                    // RM10.50 MYR
                    options.symbol = 'RM';
                    options.label = 'MYR';
                break;

                case 'nl':
                    // € 48,39
                    options.symbol = '€ ';
                    options.label = '';
                    options.decimal = ',';
                    options.thousand = '.';
                break;

                case 'nz':
                    // $#,###.00 NZD
                    options.label = 'NZD';
                break;

                case 'ph':
                    // $#,###.00 PHD
                    options.symbol = '₱';
                    options.label = 'PHP';
                break;

                case 'se':
                    // 166,25 kr
                    options.symbol = ' kr';
                    options.label = '';
                    options.decimal = ',';
                    options.thousand = '.';
                    options.format = '%v%s';
                break;

                case 'sg':
                    // S$36.00 SGD
                    options.symbol = 'S$';
                    options.label = 'SGD';
                break;

                case 'tw':
                    // NT$#,### TWD
                    options.symbol = 'NT$';
                    options.label = 'TWD';
                    options.precision = 0;
                break;
            }

            if (num !== false) {
                // deal with the javascript rounding bug
                num = self.round(num);

                if (hideLabel) {
                    return accounting.formatMoney(num, options);
                }

                return accounting.formatMoney(num, options) +' '+ options.label;
            }

            return options;
        },

        /**
         * Initialize any external tracking code
         * For now this is only Quantum
         * todo: roll in Google?
         */
        initTracking: function() {
            if (['live'].includes(mode)) {
                var qtm = document.createElement('script'); qtm.type = 'text/javascript'; qtm.async = 1;
                qtm.src = 'https://cdn.quantummetric.com/qscripts/quantum-ylo.js';
                var d = document.getElementsByTagName('script')[0];
                !window.QuantumMetricAPI && d.parentNode.insertBefore(qtm, d);
            }
        },

        /**
         * Utility to convert int to float (1750 -> 17.50) and handle Javascript rounding errors
         */
        round: function(num) {
            if (typeof num == 'string') {
                num = parseInt(num);
            }
            return Number(Math.round(num +'e2') +'e-2') / 100;
        },

        /**
         * Utility to convert float to int (17.50 -> 1750) and handle Javascript rounding errors
         */
        convert: function(num) {
            if (typeof num == 'string') {
                num = parseFloat(num);
            }
            return Number(Math.round((num * 100) +'e2') +'e-2');
        },

        /**
         * Check if user has logged in and return the type
         * Type 2 = brand partner
         * Requires app.cookie.js
         */
        getMemberType: function() {
            var cookieData = {},
                cookie = null;

            // first look for admin cookie 'myyl_admin'
            cookie = app.cookie.get('myyl_admin');

            if (cookie) {
                cookieData = JSON.parse(cookie);

                if (cookieData && cookieData.token) {
                    if (cookieData.token.member_type_id) {
                        return parseInt(cookieData.token.member_type_id, 10);
                    }
                }
            }

            // check for skava cookie 'c_yl_skava'
            cookie = app.cookie.get('c_yl_skava');
            if (cookie) {
                cookieData = JSON.parse(cookie);

                if (cookieData && cookieData.member_type_id) {
                    return parseInt(cookieData.member_type_id, 10);
                }
            }

            return false;
        },

        /**
         * Utility to load a hidden iframe
         */
        loadIframe: function(url, id) {
            // test if the iframe currently exists
            if (!$('#'+ id)[0]) {
                $('<iframe>', {
                    src: url,
                    id: id,
                    frameborder: 0,
                    scrolling: 'no'
                }).appendTo('body');
                console.log('Loading '+ url);
            }
        }

    };
    return self;
})();

app.global.init();